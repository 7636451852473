.theme-label {
  padding: 0.5em;
  border-left: 3px solid transparent;
  box-shadow: 2px 2px 5px 0px $silver;
  margin-right: 1em;
  margin-bottom: 0.5em;
  display: inline-block;
}

.partner-type-label.ui.label,
.partner-privacy-label.ui.label {
  background-color: $dark-grey;
  color: $white;
  font-weight: normal;
  padding: 0.3em 0.5em;
  font-size: 0.9em;
  line-height: 1.1em;

  > .icon {
    margin: 0 0 0 0.4em;
    font-size: 0.9em;
  }

  &.primary {
    background-color: $blue-tertiary;
  }

  &.secondary {
    background-color: $sea-green;
  }
}

.date-display {
  display: flex;
  i {
    margin: 0;
  }
  * + * {
    margin-left: 0.5rem;
  }
}

.general-status-label {
  display: flex;
  align-items: center;
  color: $green;
  font-weight: $semibold-weight;
  &::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: $green;
    border-radius: 12px;
    margin-right: 0.5rem;
  }
  &.inline {
    display: inline-block;
  }
  &.grey {
    color: $grey;
    &::before {
      background-color: $grey;
    }
  }
}

.dark-display-label.ui.label {
  display: inline-block;
  background-color: $blue-tertiary;
  color: $white;
  padding: 0.2em 0.5em 0 0.5em;
  border-radius: 50px;
  @include DefineFont(14px);
  &.secondary {
    background-color: $sea-green;
  }
}
